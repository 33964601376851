import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ModalAdd: false,
    data: []
  },

  mutations: {
    TModal(state, value) {
      state.ModalAdd = value
    },

    VData(state, value) {
      state.data = value
    },
  }
})