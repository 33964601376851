import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    success: '',
    alertMassage: '',
    
    refresh: false,
    alertBerhasil: false,
    alertGagal: false,
  },

  mutations: {
    refreshData(state, value) {
      state.refresh = value
    },

    alert(state, value) {
      state.alertMassage = value
    },

    success(state, value) {
      state.success = value
    },

    berhasilAlert(state, value) {
      state.alertBerhasil = value
    },

    gagalAlert(state, value) {
      state.alertGagal = value
    },
  }
})