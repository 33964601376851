<template>
  <div>
    <v-card class="px-5 mb-10">
      <v-row>
        <v-col cols="12" md="6" class="my-auto">
          <h3 class="primary--text">DATA JABATAN ADMINISTRATOR</h3>
        </v-col>

        <v-col cols="12" md="6" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                icon
                dark
                v-bind="attrs"
                v-on="on"
                @click="addItem()"
              >
                <v-icon>mdi-plus-circle</v-icon>
              </v-btn>
            </template>
            <span>Tambah Data</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card>

    <div>
      <v-alert v-model="alertBerhasil" type="success" dense dismissible>
        {{ alertMassage }}
      </v-alert>
      <v-alert v-model="alertGagal" type="error" dense dismissible>
        {{ alertMassage }}
      </v-alert>
    </div>

    <v-card class="px-3">
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>

      <v-data-table
        :headers="headers"
        :search="search"
        :items="data"
        :loading="ldata"
        item-key="name"
        class="mt-2"
      >
        <template v-slot:top>
          <CMadd />
          <CMedit />
          <CMdetail />
        </template>
        <template v-slot:[`item.nomor`]="{ item }">
          {{
            data
              .map(function (x) {
                return x.id;
              })
              .indexOf(item.id) + 1
          }}
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-icon color="success" v-if="item.status == 'active'"> mdi-circle </v-icon>
          <v-icon color="error" v-else> mdi-circle </v-icon>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-icon small @click="detailItem(item)"> mdi-archive </v-icon>
          <v-icon small class="ml-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import CMadd from "@/components/jabatan/administrator/add";
import CMedit from "@/components/jabatan/administrator/edit";
import CMdetail from "@/components/jabatan/administrator/detail";

import SAdd from "@/store/jabatan/administrator/add";
import SEdit from "@/store/jabatan/administrator/edit";
import SDetail from "@/store/jabatan/administrator/detail";
import SView from "@/store/jabatan/administrator/view";

export default {
  components: {
    CMadd,
    CMedit,
    CMdetail,
  },

  data: () => ({
    token: Cookie.get("token"),
    ldata: true,

    search: "",

    headers: [
      {
        text: "No",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "ID BKN", value: "idBKN" },
      { text: "Nama Jabatan", value: "nama" },
      { text: "Status", value: "status", align: "center", width: "100px", sortable: false },
      {
        text: "Action",
        align: "center",
        value: "action",
        width: "100px",
        sortable: false,
      },
    ],

    data: [],
  }),

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url = process.env.VUE_APP_API + "ref/administrator/all";
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          SView.commit("refreshData", false);
          this.data = res.data.mapData.referensi.administrator;
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout();
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    addItem(item) {
      SAdd.commit("TModal", true);
      SAdd.commit("VData", item);
    },

    editItem(item) {
      SEdit.commit("TModal", true);
      SEdit.commit("VData", item);
    },

    detailItem(item) {
      SDetail.commit("TModal", true);
      SDetail.commit("VData", item);
    },
  },
};
</script>
